import { registerApplication, start } from "single-spa";

const loginAppPaths = ["/login", "/forgot-password", "/onboarding"];
const eprepaidAppPaths = ["/iriscard"];
const recaudosAppPaths = ["/irispay"];
const complementosAppPaths = ["/complements"];

const loginActiveWhen = (location: Location) =>
  loginAppPaths.find((path) => location.pathname.startsWith(path)) !==
  undefined;

const navBarActiveWhen = (location: Location) => !loginActiveWhen(location);

const eprepaidActiveWhen = (location: Location) =>
  navBarActiveWhen(location) &&
  eprepaidAppPaths.find((path) => location.pathname.startsWith(path)) !==
    undefined;

const recaudosActiveWhen = (location: Location) =>
    navBarActiveWhen(location) &&
    recaudosAppPaths.find((path) => location.pathname.startsWith(path)) !== 
      undefined;

const complementosActiveWhen = (location: Location) =>
    navBarActiveWhen(location) &&
    complementosAppPaths.find((path) => location.pathname.startsWith(path)) !== 
      undefined;

const bancaDigitalActiveWhen = (location: Location) =>
  navBarActiveWhen(location) && !eprepaidActiveWhen(location) && !recaudosActiveWhen(location) && !complementosActiveWhen(location);

registerApplication({
  name: "@iris/login",
  app: () => System.import("@iris/login"),
  activeWhen: loginActiveWhen,
});

registerApplication({
  name: "@iris/navbar",
  app: () => System.import("@iris/navbar"),
  activeWhen: navBarActiveWhen,
});

registerApplication({
  name: "@iris/bancadigital",
  app: () => System.import("@iris/bancadigital"),
  activeWhen: bancaDigitalActiveWhen,
});

registerApplication({
  name: "@iris/iriscard",
  app: () => System.import("@iris/iriscard"),
  activeWhen: eprepaidActiveWhen,
});

registerApplication({
  name: "@iris/irispay",
  app: () => System.import("@iris/irispay"),
  activeWhen: recaudosActiveWhen,
});

registerApplication({
  name: "@iris/complementos",
  app: () => System.import("@iris/complementos"),
  activeWhen: complementosActiveWhen,
});

start({
  urlRerouteOnly: true,
});
